<script setup>
import { formatDate } from '@/utils/date.js'
import Image from '@/components/Image'

const props = defineProps({
  article: {
    type: Object,
    required: true
  }
})

const title = computed(() => {
  return props.article.attributes.title
})
const summary = computed(() => {
  return props.article.attributes.summary
})
const date = computed(() => {
  return formatDate(props.article.attributes.publishedAt)
})
const isHighlight = computed(() => {
  return !!props.article.attributes.highlight
})
const tagName = computed(() => {
  return props.article.attributes.tag.data.attributes.name
})
const coverObj = props.article.attributes.cover.data.attributes

</script>

<template>
  <NuxtLink :to="{ path: '/blog/articles/' + article.attributes.slug + '/' }" class="bg-white h-full">
    <div class="relative h-full" :class="{ 'md:flex md:flex-row': isHighlight }">
      <Image
        :alt="title"
        :class-list="`cover object-cover ${ isHighlight ? 'md:min-h-full md:w-96 lg:w-120 xl:w-160' : 'md:w-30vw lg:w-40vw' }`"
        :content="coverObj" />
      <div class="flex flex-col p-9" :class="{ 'md:w-1/2': isHighlight }">
        <div class="mb-10 font-semibold" :class="{ 'text-2.5xl sm:text-4.25xl': isHighlight, 'text-2.5xl': !isHighlight }">
          {{ title }}
        </div>
        <div class="mb-10 block font-family-sspro text-base max-h-24">
          <div class="line-clamp-4">
            {{ summary }}
          </div>
        </div>
        <div class="text-sm flex justify-end font-family-sspro">
          <span class="bg-tag text-white rounded-2xl px-4 py-1">{{ tagName }}</span>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
