<script setup>
import Card from '@/components/Card.vue'
import Carousel from '@/components/Carousel.vue'
const props = defineProps({
  tag: {
    type: String,
    default: ''
  }
})
const { restOfArticles, highlightedArticles } = useArticlesByTag(props.tag)

</script>

<template>
  <div class="flex flex-col md:flex-row flex-wrap justify-between items-center">
    <template v-if="highlightedArticles.length === 1">
      <Card :article="highlightedArticles[0]"
        class="mb-12 shadow-xl sm:shadow-none hover:shadow-2xl sm:transition sm:duration-500 sm:ease-in-out sm:transform sm:hover:-translate-y-1 w-full"></Card>
    </template>
    <Carousel v-else-if="highlightedArticles.length > 1" :articles="highlightedArticles" />
    <div class="flex flex-col sm:grid md:grid-cols-2 xl:grid-cols-3 lg:gap-x-12 space-y-8 sm:space-y-0 sm:gap-8 w-full">
      <Card v-for="article in restOfArticles" :key="article.slug" :article="article"
        class="mb-12 shadow-xl sm:shadow-none hover:shadow-2xl sm:transition sm:duration-500 sm:ease-in-out sm:transform sm:hover:-translate-y-1 sm:hover:scale-105"></Card>
    </div>
  </div>
</template>