import { useStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'

export const useArticlesByTag = (tag) => {
  const store = useStore()
  const { articles } = storeToRefs(store)

  const articlesByTag = articles.value.filter((a) => tag === '' || a.attributes.tag.data.attributes.slug === tag)
  const highlightedArticles = articlesByTag.filter(a => a.attributes.highlight)
  const restOfArticles = articlesByTag.filter(a => !a.attributes.highlight)
  return { articles: articlesByTag, highlightedArticles, restOfArticles }
}