<script setup>
import Card from '@/components/Card.vue'
const props = defineProps({
  articles: {
    type: Array,
    default: () => []
  }
})

const currentItem = ref(0)
const timer = reactive({})

function resetTimer (n) {
  clearTimeout(timer.value)
  currentItem.value = n
  timer.value = setTimeout(() => {
    nextItem()
  }, 5000)
}

function nextItem () {
  const N = currentItem.value === props.articles.length - 1 ? 0 : currentItem.value + 1
  resetTimer(N)
}

function prevItem () {
  const N = currentItem.value === 0 ? props.articles.length - 1 : currentItem.value - 1
  resetTimer(N)
}

onMounted(() => {
  resetTimer(0)
})

</script>

<template>
  <div class="carousel relative shadow-2xl bg-white w-full mb-12">
    <div class="carousel-inner relative overflow-hidden w-full">
      <div v-for="(article, index) in articles" :key="article.attributes.slug">
        <input class="carousel-open" type="radio" :id="`carousel-${index}`" name="carousel" aria-hidden="true" hidden="" :checked="currentItem === index">
        <div class="carousel-item absolute opacity-0">
          <Card :article="article" class="mb-12"></Card>
        </div>
        <div class="controls hidden absolute cursor-pointer text-xl font-bold text-black
            text-center -ml-3 left-1/2 top-3 md:left-3 md:ml-0">
          <label :for="`carousel-${Math.abs((index-1) % articles.length)}`" @click.prevent="prevItem"
            class="prev w-6 h-6 cursor-pointer hover:text-mnet bg-white rounded-full leading-tight mr-2">‹</label>
          <label :for="`carousel-${Math.abs((index+1) % articles.length)}`" @click.prevent="nextItem"
            class="next w-6 h-6 cursor-pointer hover:text-mnet bg-white rounded-full leading-tight">›</label>
        </div>
        <ol class="carousel-indicators flex flex-row justify-center">
          <li v-for="(_, bulletIndex) in articles" :key="`bullet-${bulletIndex}`">
            <label :for="`carousel-${bulletIndex}`" :class="index === bulletIndex ? 'text-mnet' : 'text-black'" class="carousel-bullet cursor-pointer block text-4xl text-black hover:text-mnet mx-1">•</label>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
.carousel-open:checked ~ .controls {
  display: flex;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
}
.carousel-open:checked ~ .carousel-indicators {
  display: flex;
}
</style>